<template>
  <BaseDetailSimple
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :input-attributes="inputAttributes"
    entity-type="herNorway"
    locale-section="pages.herNorway"
    field-section="herNo"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "HerNorwayDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        locality
      `,
      inputAttributes: {
        locality: { type: "number", required: true }
      }
    };
  }
};
</script>
